.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}

.certain-category-search.ant-select-focused .certain-category-icon {
  color: #108ee9;
}

.certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.utils__content {
  max-width: inherit !important;
}

.highlight {
  color: #f96859;
}

.ant-btn-primary {
  background-color: #00468c;
  border-color: #00468c;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #1961a9;
  border-color: #1961a9;
}
.utils__link--blue {
  color: #1961a9;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1961a9;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1961a9;
  border-color: #1961a9;
}
.ant-menu-dark {
  background: #023b75;
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-dark .ant-menu-sub {
  background: white;
}

.ant-menu-dark .ant-menu-item {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-dark .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-dark .ant-menu-sub .ant-menu-item > a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: #023b75;
  color: white;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected > a {
  color: white;
}

.ant-menu-horizontal {
  line-height: 50px;
}
